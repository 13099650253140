@import 'styles/variables';

.nice-radio-group-wrapper {
  .ant-radio-group {
    .ant-radio-button-wrapper {
      color: @primaryDarkBlueColor;
      border: 1px solid #d9d9d9;
      border-radius: 25px;
      background-color: white;
      margin: 0px 15px 5px 0px;
      font-size: 13px;
      letter-spacing: 0.2px;
      height: 38px;
    }

    .ant-radio-button-checked {
      background-color: #e5f0ff;
      border-radius: 25px;
      border: 1px solid #d9d9d9;
    }

    *::before,
    *::after {
      background: none;
      background-color: transparent !important;
    }

    .ant-radio-button-wrapper-checked {
      background-color: #e5f0ff;
      border-radius: 25px;
      border: none;
    }

    input {
      width: 100px;
      background-color: #f1f1f1;
      color: @primaryDarkBlueColor;
      outline: 0;
      text-align: center;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: @primaryDarkBlueColor;
      opacity: 1;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: @primaryDarkBlueColor;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: @primaryDarkBlueColor;
    }
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: #e5f0ff;
    color: #050d43;
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);