.about-you-form-styling {
  .ant-input {
    background-color: transparent;
    border: 1px solid #979797;
    color: #686c80 !important;
  }
  #phone {
    input {
      height: 36px;
      border: none !important;
    }
  }
  .ant-form-item-label {
    padding-bottom: 0;
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);