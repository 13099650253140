.rating-question {
  .ant-rate-star-half .ant-rate-star-first,
  .ant-rate-star-full .ant-rate-star-second {
    color: inherit !important;
  }
  .ant-rate-star-second {
    color: rgba(0, 0, 0, 0.1);
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);