// Flag source get from BE

.react-tel-input .selected-flag .arrow {
  left: 25px;
}

.react-tel-input .flag {
  width: 21px;
  height: 13px;
  background-position: center;
  background-size: cover;
}

.react-tel-input .flag {
  &.af {
    background-image: url('https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg');
  }

  &.al {
    background-image: url('https://flagcdn.com/al.svg');
  }

  &.dz {
    background-image: url('https://flagcdn.com/dz.svg');
  }

  &.as {
    background-image: url('https://flagcdn.com/as.svg');
  }

  &.ad {
    background-image: url('https://flagcdn.com/ad.svg');
  }

  &.ao {
    background-image: url('https://flagcdn.com/ao.svg');
  }

  &.ai {
    background-image: url('https://flagcdn.com/ai.svg');
  }

  &.tf {
    background-image: url('https://flagcdn.com/tf.svg');
  }

  &.aq {
    background-image: url('https://flagcdn.com/aq.svg');
  }

  &.ag {
    background-image: url('https://flagcdn.com/ag.svg');
  }

  &.ar {
    background-image: url('https://flagcdn.com/ar.svg');
  }

  &.am {
    background-image: url('https://flagcdn.com/am.svg');
  }

  &.aw {
    background-image: url('https://flagcdn.com/aw.svg');
  }

  &.au {
    background-image: url('https://flagcdn.com/au.svg');
  }

  &.at {
    background-image: url('https://flagcdn.com/at.svg');
  }

  &.az {
    background-image: url('https://flagcdn.com/az.svg');
  }

  &.bh {
    background-image: url('https://flagcdn.com/bh.svg');
  }

  &.je {
    background-image: url('https://flagcdn.com/je.svg');
  }

  &.bd {
    background-image: url('https://flagcdn.com/bd.svg');
  }

  &.bb {
    background-image: url('https://flagcdn.com/bb.svg');
  }

  &.by {
    background-image: url('https://flagcdn.com/by.svg');
  }

  &.be {
    background-image: url('https://flagcdn.com/be.svg');
  }

  &.bz {
    background-image: url('https://flagcdn.com/bz.svg');
  }

  &.bj {
    background-image: url('https://flagcdn.com/bj.svg');
  }

  &.bm {
    background-image: url('https://flagcdn.com/bm.svg');
  }

  &.bt {
    background-image: url('https://flagcdn.com/bt.svg');
  }

  &.bo {
    background-image: url('https://flagcdn.com/bo.svg');
  }

  &.ba {
    background-image: url('https://flagcdn.com/ba.svg');
  }

  & .bw {
    background-image: url('https://flagcdn.com/bw.svg');
  }

  &.bv {
    background-image: url('https://flagcdn.com/bv.svg');
  }

  &.br {
    background-image: url('https://flagcdn.com/br.svg');
  }

  &.bn {
    background-image: url('https://flagcdn.com/bn.svg');
  }

  &.bg {
    background-image: url('https://flagcdn.com/bg.svg');
  }

  &.bf {
    background-image: url('https://flagcdn.com/bf.svg');
  }

  &.bi {
    background-image: url('https://flagcdn.com/bi.svg');
  }

  &.cv {
    background-image: url('https://flagcdn.com/cv.svg');
  }

  &.kh {
    background-image: url('https://flagcdn.com/kh.svg');
  }

  &.cm {
    background-image: url('https://flagcdn.com/cm.svg');
  }

  &.ca {
    background-image: url('https://flagcdn.com/ca.svg');
  }

  &.ky {
    background-image: url('https://flagcdn.com/ky.svg');
  }

  &.cf {
    background-image: url('https://flagcdn.com/cf.svg');
  }

  &.td {
    background-image: url('https://flagcdn.com/td.svg');
  }

  &.cl {
    background-image: url('https://flagcdn.com/cl.svg');
  }

  &.cn {
    background-image: url('https://flagcdn.com/cn.svg');
  }

  &.cx {
    background-image: url('https://flagcdn.com/cx.svg');
  }

  &.cc {
    background-image: url('https://flagcdn.com/cc.svg');
  }

  &.bl {
    background-image: url('https://flagcdn.com/bl.svg');
  }

  &.co {
    background-image: url('https://flagcdn.com/co.svg');
  }

  &.bs {
    background-image: url('https://flagcdn.com/bs.svg');
  }

  &.cd {
    background-image: url('https://flagcdn.com/cd.svg');
  }

  &.cg {
    background-image: url('https://flagcdn.com/cg.svg');
  }

  &.ck {
    background-image: url('https://flagcdn.com/ck.svg');
  }

  &.cr {
    background-image: url('https://flagcdn.com/cr.svg');
  }

  &.hr {
    background-image: url('https://flagcdn.com/hr.svg');
  }

  &.cu {
    background-image: url('https://flagcdn.com/cu.svg');
  }

  &.cw {
    background-image: url('https://flagcdn.com/cw.svg');
  }

  &.cy {
    background-image: url('https://flagcdn.com/cy.svg');
  }

  &.cz {
    background-image: url('https://flagcdn.com/cz.svg');
  }

  &.ci {
    background-image: url('https://flagcdn.com/ci.svg');
  }

  &.dk {
    background-image: url('https://flagcdn.com/dk.svg');
  }

  &.dj {
    background-image: url('https://flagcdn.com/dj.svg');
  }

  &.do {
    background-image: url('https://flagcdn.com/do.svg');
  }

  &.dm {
    background-image: url('https://flagcdn.com/dm.svg');
  }

  &.ec {
    background-image: url('https://flagcdn.com/ec.svg');
  }

  &.eg {
    background-image: url('https://flagcdn.com/eg.svg');
  }

  &.sv {
    background-image: url('https://flagcdn.com/sv.svg');
  }

  &.gq {
    background-image: url('https://flagcdn.com/gq.svg');
  }

  &.er {
    background-image: url('https://flagcdn.com/er.svg');
  }

  &.ee {
    background-image: url('https://flagcdn.com/ee.svg');
  }

  &.sz {
    background-image: url('https://flagcdn.com/sz.svg');
  }

  &.et {
    background-image: url('https://flagcdn.com/et.svg');
  }

  &.fk {
    background-image: url('https://flagcdn.com/fk.svg');
  }

  &.fo {
    background-image: url('https://flagcdn.com/fo.svg');
  }

  &.fj {
    background-image: url('https://flagcdn.com/fj.svg');
  }

  &.fi {
    background-image: url('https://flagcdn.com/fi.svg');
  }

  &.fr {
    background-image: url('https://flagcdn.com/fr.svg');
  }

  &.pf {
    background-image: url('https://flagcdn.com/pf.svg');
  }

  &.wf {
    background-image: url('https://flagcdn.com/wf.svg');
  }

  &.ga {
    background-image: url('https://flagcdn.com/ga.svg');
  }

  &.gm {
    background-image: url('https://flagcdn.com/gm.svg');
  }

  &.ge {
    background-image: url('https://flagcdn.com/ge.svg');
  }

  &.de {
    background-image: url('https://flagcdn.com/de.svg');
  }

  &.gh {
    background-image: url('https://flagcdn.com/gh.svg');
  }

  &.gi {
    background-image: url('https://flagcdn.com/gi.svg');
  }

  &.gl {
    background-image: url('https://flagcdn.com/gl.svg');
  }

  &.gd {
    background-image: url('https://flagcdn.com/gd.svg');
  }

  &.gp {
    background-image: url('https://flagcdn.com/gp.svg');
  }

  &.gu {
    background-image: url('https://flagcdn.com/gu.svg');
  }

  &.gt {
    background-image: url('https://flagcdn.com/gt.svg');
  }

  &.gg {
    background-image: url('https://flagcdn.com/gg.svg');
  }

  &.gf {
    background-image: url('https://flagcdn.com/gf.svg');
  }

  &.gn {
    background-image: url('https://flagcdn.com/gn.svg');
  }

  &.gw {
    background-image: url('https://flagcdn.com/gw.svg');
  }

  &.gy {
    background-image: url('https://flagcdn.com/gy.svg');
  }

  &.ht {
    background-image: url('https://flagcdn.com/ht.svg');
  }

  &.hm {
    background-image: url('https://flagcdn.com/hm.svg');
  }

  &.gr {
    background-image: url('https://flagcdn.com/gr.svg');
  }

  &.hn {
    background-image: url('https://flagcdn.com/hn.svg');
  }

  &.hk {
    background-image: url('https://flagcdn.com/hk.svg');
  }

  &.hu {
    background-image: url('https://flagcdn.com/hu.svg');
  }

  &.is {
    background-image: url('https://flagcdn.com/is.svg');
  }

  &.in {
    background-image: url('https://flagcdn.com/in.svg');
  }

  &.id {
    background-image: url('https://flagcdn.com/id.svg');
  }

  &.ir {
    background-image: url('https://flagcdn.com/ir.svg');
  }

  &.iq {
    background-image: url('https://flagcdn.com/iq.svg');
  }

  &.ie {
    background-image: url('https://flagcdn.com/ie.svg');
  }

  &.mr {
    background-image: url('https://flagcdn.com/mr.svg');
  }

  &.im {
    background-image: url('https://flagcdn.com/im.svg');
  }

  &.il {
    background-image: url('https://flagcdn.com/il.svg');
  }

  &.it {
    background-image: url('https://flagcdn.com/it.svg');
  }

  &.jm {
    background-image: url('https://flagcdn.com/jm.svg');
  }

  &.jp {
    background-image: url('https://flagcdn.com/jp.svg');
  }

  &.jo {
    background-image: url('https://flagcdn.com/jo.svg');
  }

  &.kz {
    background-image: url('https://flagcdn.com/kz.svg');
  }

  &.ke {
    background-image: url('https://flagcdn.com/ke.svg');
  }

  &.ki {
    background-image: url('https://flagcdn.com/ki.svg');
  }

  &.xk {
    background-image: url('https://flagcdn.com/xk.svg');
  }

  &.kw {
    background-image: url('https://flagcdn.com/kw.svg');
  }

  &.kg {
    background-image: url('https://flagcdn.com/kg.svg');
  }

  &.la {
    background-image: url('https://flagcdn.com/la.svg');
  }

  &.lv {
    background-image: url('https://flagcdn.com/lv.svg');
  }

  &.lb {
    background-image: url('https://flagcdn.com/lb.svg');
  }

  &.ls {
    background-image: url('https://flagcdn.com/ls.svg');
  }

  &.lr {
    background-image: url('https://flagcdn.com/lr.svg');
  }

  &.ly {
    background-image: url('https://flagcdn.com/ly.svg');
  }

  &.li {
    background-image: url('https://flagcdn.com/li.svg');
  }

  &.lt {
    background-image: url('https://flagcdn.com/lt.svg');
  }

  &.lu {
    background-image: url('https://flagcdn.com/lu.svg');
  }

  &.mo {
    background-image: url('https://flagcdn.com/mo.svg');
  }

  &.mg {
    background-image: url('https://flagcdn.com/mg.svg');
  }

  &.mw {
    background-image: url('https://flagcdn.com/mw.svg');
  }

  &.my {
    background-image: url('https://flagcdn.com/my.svg');
  }

  &.mv {
    background-image: url('https://flagcdn.com/mv.svg');
  }

  &.ml {
    background-image: url('https://flagcdn.com/ml.svg');
  }

  &.mt {
    background-image: url('https://flagcdn.com/mt.svg');
  }

  &.mh {
    background-image: url('https://flagcdn.com/mh.svg');
  }

  &.mq {
    background-image: url('https://flagcdn.com/mq.svg');
  }

  &.mu {
    background-image: url('https://flagcdn.com/mu.svg');
  }

  &.yt {
    background-image: url('https://flagcdn.com/yt.svg');
  }

  &.mx {
    background-image: url('https://flagcdn.com/mx.svg');
  }

  &.fm {
    background-image: url('https://flagcdn.com/fm.svg');
  }

  &.md {
    background-image: url('https://flagcdn.com/md.svg');
  }

  &.mc {
    background-image: url('https://flagcdn.com/mc.svg');
  }

  &.mn {
    background-image: url('https://flagcdn.com/mn.svg');
  }

  &.me {
    background-image: url('https://flagcdn.com/me.svg');
  }

  &.ms {
    background-image: url('https://flagcdn.com/ms.svg');
  }

  &.ma {
    background-image: url('https://flagcdn.com/ma.svg');
  }

  &.mz {
    background-image: url('https://flagcdn.com/mz.svg');
  }

  &.mm {
    background-image: url('https://flagcdn.com/mm.svg');
  }

  &.na {
    background-image: url('https://flagcdn.com/na.svg');
  }

  &.nr {
    background-image: url('https://flagcdn.com/nr.svg');
  }

  &.np {
    background-image: url('https://flagcdn.com/np.svg');
  }

  &.nl {
    background-image: url('https://flagcdn.com/nl.svg');
  }

  &.nc {
    background-image: url('https://flagcdn.com/nc.svg');
  }

  &.pg {
    background-image: url('https://flagcdn.com/pg.svg');
  }

  &.nz {
    background-image: url('https://flagcdn.com/nz.svg');
  }

  &.ni {
    background-image: url('https://flagcdn.com/ni.svg');
  }

  &.ne {
    background-image: url('https://flagcdn.com/ne.svg');
  }

  &.ng {
    background-image: url('https://flagcdn.com/ng.svg');
  }

  &.nu {
    background-image: url('https://flagcdn.com/nu.svg');
  }

  &.nf {
    background-image: url('https://flagcdn.com/nf.svg');
  }

  &.kp {
    background-image: url('https://flagcdn.com/kp.svg');
  }

  &.mk {
    background-image: url('https://flagcdn.com/mk.svg');
  }

  &.mp {
    background-image: url('https://flagcdn.com/mp.svg');
  }

  &.no {
    background-image: url('https://flagcdn.com/no.svg');
  }

  &.om {
    background-image: url('https://flagcdn.com/om.svg');
  }

  &.um {
    background-image: url('https://flagcdn.com/um.svg');
  }

  &.pk {
    background-image: url('https://flagcdn.com/pk.svg');
  }

  &.pw {
    background-image: url('https://flagcdn.com/pw.svg');
  }

  &.ps {
    background-image: url('https://flagcdn.com/ps.svg');
  }

  &.pa {
    background-image: url('https://flagcdn.com/pa.svg');
  }

  &.py {
    background-image: url('https://flagcdn.com/py.svg');
  }

  &.pe {
    background-image: url('https://flagcdn.com/pe.svg');
  }

  &.ph {
    background-image: url('https://flagcdn.com/ph.svg');
  }

  &.pn {
    background-image: url('https://flagcdn.com/pn.svg');
  }

  &.pl {
    background-image: url('https://flagcdn.com/pl.svg');
  }

  &.pt {
    background-image: url('https://flagcdn.com/pt.svg');
  }

  &.pr {
    background-image: url('https://flagcdn.com/pr.svg');
  }

  &.qa {
    background-image: url('https://flagcdn.com/qa.svg');
  }

  &.ro {
    background-image: url('https://flagcdn.com/ro.svg');
  }

  &.ru {
    background-image: url('https://flagcdn.com/ru.svg');
  }

  &.rw {
    background-image: url('https://flagcdn.com/rw.svg');
  }

  &.re {
    background-image: url('https://flagcdn.com/re.svg');
  }

  &.kn {
    background-image: url('https://flagcdn.com/kn.svg');
  }

  &.sh {
    background-image: url('https://flagcdn.com/sh.svg');
  }

  &.lc {
    background-image: url('https://flagcdn.com/lc.svg');
  }

  &.mf {
    background-image: url('https://flagcdn.com/mf.svg');
  }

  &.pm {
    background-image: url('https://flagcdn.com/pm.svg');
  }

  &.vc {
    background-image: url('https://flagcdn.com/vc.svg');
  }

  &.ws {
    background-image: url('https://flagcdn.com/ws.svg');
  }

  &.sm {
    background-image: url('https://flagcdn.com/sm.svg');
  }

  &.sa {
    background-image: url('https://flagcdn.com/sa.svg');
  }

  &.sn {
    background-image: url('https://flagcdn.com/sn.svg');
  }

  &.rs {
    background-image: url('https://flagcdn.com/rs.svg');
  }

  &.sc {
    background-image: url('https://flagcdn.com/sc.svg');
  }

  &.sl {
    background-image: url('https://flagcdn.com/sl.svg');
  }

  &.sg {
    background-image: url('https://flagcdn.com/sg.svg');
  }

  &.sx {
    background-image: url('https://flagcdn.com/sx.svg');
  }

  &.sk {
    background-image: url('https://flagcdn.com/sk.svg');
  }

  &.si {
    background-image: url('https://flagcdn.com/si.svg');
  }

  &.sb {
    background-image: url('https://flagcdn.com/sb.svg');
  }

  &.so {
    background-image: url('https://flagcdn.com/so.svg');
  }

  &.za {
    background-image: url('https://flagcdn.com/za.svg');
  }

  &.gs {
    background-image: url('https://flagcdn.com/gs.svg');
  }

  &.kr {
    background-image: url('https://flagcdn.com/kr.svg');
  }

  &.ss {
    background-image: url('https://flagcdn.com/ss.svg');
  }

  &.es {
    background-image: url('https://flagcdn.com/es.svg');
  }

  &.lk {
    background-image: url('https://flagcdn.com/lk.svg');
  }

  &.sd {
    background-image: url('https://flagcdn.com/sd.svg');
  }

  &.sr {
    background-image: url('https://flagcdn.com/sr.svg');
  }

  &.sj {
    background-image: url('https://flagcdn.com/sj.svg');
  }

  &.se {
    background-image: url('https://flagcdn.com/se.svg');
  }

  &.ch {
    background-image: url('https://flagcdn.com/ch.svg');
  }

  &.sy {
    background-image: url('https://flagcdn.com/sy.svg');
  }

  &.st {
    background-image: url('https://flagcdn.com/st.svg');
  }

  &.tw {
    background-image: url('https://flagcdn.com/tw.svg');
  }

  &.tj {
    background-image: url('https://flagcdn.com/tj.svg');
  }

  &.th {
    background-image: url('https://flagcdn.com/th.svg');
  }

  &.tl {
    background-image: url('https://flagcdn.com/tl.svg');
  }

  &.tg {
    background-image: url('https://flagcdn.com/tg.svg');
  }

  &.tk {
    background-image: url('https://flagcdn.com/tk.svg');
  }

  &.to {
    background-image: url('https://flagcdn.com/to.svg');
  }

  &.tt {
    background-image: url('https://flagcdn.com/tt.svg');
  }

  &.tn {
    background-image: url('https://flagcdn.com/tn.svg');
  }

  &.tr {
    background-image: url('https://flagcdn.com/tr.svg');
  }

  &.tm {
    background-image: url('https://flagcdn.com/tm.svg');
  }

  &.tc {
    background-image: url('https://flagcdn.com/tc.svg');
  }

  &.tv {
    background-image: url('https://flagcdn.com/tv.svg');
  }

  &.ug {
    background-image: url('https://flagcdn.com/ug.svg');
  }

  &.ua {
    background-image: url('https://flagcdn.com/ua.svg');
  }

  &.km {
    background-image: url('https://flagcdn.com/km.svg');
  }

  &.ae {
    background-image: url('https://flagcdn.com/ae.svg');
  }

  &.gb {
    background-image: url('https://flagcdn.com/gb.svg');
  }

  &.us {
    background-image: url('https://flagcdn.com/us.svg');
  }

  &.tz {
    background-image: url('https://flagcdn.com/tz.svg');
  }

  &.uy {
    background-image: url('https://flagcdn.com/uy.svg');
  }

  &.uz {
    background-image: url('https://flagcdn.com/uz.svg');
  }

  &.vu {
    background-image: url('https://flagcdn.com/vu.svg');
  }

  &.va {
    background-image: url('https://flagcdn.com/va.svg');
  }

  &.ve {
    background-image: url('https://flagcdn.com/ve.svg');
  }

  &.vn {
    background-image: url('https://flagcdn.com/vn.svg');
  }

  &.vg {
    background-image: url('https://flagcdn.com/vg.svg');
  }

  &.vi {
    background-image: url('https://flagcdn.com/vi.svg');
  }

  &.ye {
    background-image: url('https://flagcdn.com/ye.svg');
  }

  &.zm {
    background-image: url('https://flagcdn.com/zm.svg');
  }

  &.zw {
    background-image: url('https://flagcdn.com/zw.svg');
  }

  &.ax {
    background-image: url('https://flagcdn.com/ax.svg');
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);