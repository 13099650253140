.mobile-number-input-container {
  font-family: inherit;
  padding: 1px 0;
  display: flex;
  align-items: center;

  .form-control {
    background: unset;
  }

  .mobile-number-input {
    width: 100%;
    border: none;
    padding-left: 80px;
    font-size: 16px;

    &:focus {
      box-shadow: none;
    }
  }

  .mobile-number-button {
    border: none;
    background-color: #f5f5f5 !important;
    min-width: 70px;

    .selected-flag {
      border-radius: 0;
      padding-left: 22px;
      width: 100%;

      &:hover {
        background-color: #f5f5f5 !important;
      }
    }

    .flag {
      transform: scale(1.3);
      margin-right: 10px;
    }
  }

  .mobile-number-dropdown {
    .search {
      background-color: white;
      z-index: 1;
      padding: 10px;

      .search-emoji {
        display: none;
      }

      .search-box {
        width: 100%;
        line-height: 20px;
        margin-left: 0;
      }
    }
  }

  .flag-dropdown.open {
    z-index: 50;
  }
}

.ant-form-item-has-error {
  .mobile-number-input-container {
    border-color: red !important;
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);