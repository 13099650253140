.previewProposal {
}

.messageInput {
  border: 1px solid #dddddd !important;
  border-radius: 5px !important;

  background-color: #fff !important;
}

.imInterestedText {
  font-size: 16px;
}

.addNoteText {
  font-size: 12px;
  font-weight: bold;
  line-height: 26px;
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);