.custom-faqs {
  background-color: #f9f9fa;
  padding-left: 1000px;
  padding-right: 1000px;
  margin-left: -1000px;
  margin-right: -1000px;

  .custom-external-link {
    a {
      color: #da3a6d;

      img {
        height: 15px;
        margin-top: 2px;
        margin-left: 10px;
      }
    }
  }

  .radius-10 {
    border-radius: 10px;
  }

  .ant-collapse {
    margin: auto -16px;
  }

  .ant-collapse-header {
    font-weight: 600;
  }
}

.custom-further-info {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding-left: 24px;
      position: relative;
    }
    li::before {
      content: '•';
      position: absolute;
      left: 0;
      padding-right: 15px;
      color: #050d43;
      font-size: 25px;
    }
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);