.auth-layout {
  background: linear-gradient(
    90deg,
    rgba(223, 223, 223, 1) 0%,
    rgba(239, 239, 239, 1) 20%,
    rgba(247, 247, 247, 1) 30%,
    rgba(255, 255, 255, 1) 50%,
    rgba(247, 247, 247, 1) 70%,
    rgba(239, 239, 239, 1) 80%,
    rgba(223, 223, 223, 1) 100%
  );
  min-height: 100vh;

  .top-part {
    width: 100%;
    height: 150px;
    position: relative;
  }

  @media screen and (max-width: 768px) {
    .top-part {
      width: 100%;
      height: 120px;
      position: relative;
    }
  }

  .small-logo {
    height: 40px;
    position: absolute;
    top: 37px;
    left: 30px;
  }

  .goto-signin {
    position: absolute;
    top: 45px;
    right: 20px;

    span {
      margin-right: 16px;
      color: #6b7d8b;
    }

    a {
      color: #eb1f6d;
    }
  }

  @media screen and (min-width: 768px) {
    .small-logo {
      height: 49px;
      top: 47px;
      left: 48px;
    }

    .goto-signin {
      top: 50px;
      right: 60px;
    }
  }

  .page-container {
    width: 90%;
    max-width: 600px;
    margin: 0 auto;
  }

  &.background2 {
    background: radial-gradient(
      134.37% 92.76% at 50% 177.72%,
      #c4c4c4 0%,
      rgba(196, 196, 196, 0.688335) 46.5%,
      rgba(196, 196, 196, 0) 100%
    );

    .page-container {
      max-width: 1100px;
    }
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);