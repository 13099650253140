/* FONTS */

/* LARSSEIT */
@font-face {
  font-family: 'Larsseit';
  src: url('assets/fonts/Larsseit.otf');
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'Larsseit-Medium';
  src: url('assets/fonts/Larsseit-Medium.otf');
  font-style: normal;
  font-weight: 500;
}

/* END OF LARSSEIT */

/* GILROY */
@font-face {
  font-family: Gilroy;
  src: local('Gilroy Light'), local('Gilroy-Light'), url('./assets/fonts/gilroy/Gilroy-Light.otf');
  font-weight: 300;
}

@font-face {
  font-family: Gilroy;
  src: local('Gilroy Regular'), local('Gilroy-Regular'), url('./assets/fonts/gilroy/Gilroy-Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: Gilroy;
  src: local('Gilroy Medium'), local('Gilroy-Medium'), url('./assets/fonts/gilroy/Gilroy-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: Gilroy;
  src: local('Gilroy SemiBold'), local('Gilroy-SemiBold'), url('./assets/fonts/gilroy/Gilroy-SemiBold.otf');
  font-weight: 600;
}

@font-face {
  font-family: Gilroy;
  src: local('Bold'), local('Gilroy-Bold'), url('./assets/fonts/gilroy/Gilroy-Bold.otf');
  font-weight: 700;
}

/* END OF GILROY */

/* END OF FONTS */

:root {
  --border-radius: 4px;
  --gray-100: #f5f5f5;
  --gray-400: #ddd;
  --primary-color: #0060ff;
}

body {
  margin: 0;
  font-family: Gilroy, Larsseit, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

h1 {
  font-size: 2em
}

h2 {
  font-size: 1.5rem;
}

.emoji-popover .ant-popover-inner-content {
  padding: 0;
}

.radio-green-dot input[type='radio']:checked {
  border-color: #039f61 !important;
  background: #039f61;
}

@tailwind base;
@tailwind components;
@tailwind utilities;