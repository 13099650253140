.custom-form-item {
  position: relative;

  .ant-form-item-label {
    position: absolute;
    width: 600px;
    top: -6px;
    word-spacing: 2px;

    > label {
      font-size: 14px;
      color: #808080;
      z-index: 5;
      font-weight: 600;
      top: 12px;
      left: 20px;
      height: 30px;
    }
  }

  &.small-label > .ant-form-item-label > label {
    font-size: 10px;
    transition: font-size 0.1s ease;
  }
}

.custom-signin-input {
  height: 60px;
  box-shadow: 0px 10px 10px rgb(187 187 187 / 25%);

  > input.ant-input {
    font-size: 14px;
    font-weight: 600;
    color: #050d43 !important;
    background-color: white !important;
    height: 24px;
    margin-top: 20px;
    margin-left: 8px;
    letter-spacing: 0.5px;
    border: none;
  }
}

.custom-signin-input.ant-input-affix-wrapper {
  background-color: white !important;
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);