.custom-slider-styling {
  .ant-slider-rail {
    background-color: #d9d9d9;
  }
  .ant-slider-dot {
    top: -6px;
    width: 16px;
    height: 16px;
  }
  .ant-slider-mark-text {
    font-size: x-large;
  }
  .ant-slider-track {
    background-color: #eb1f6d !important;
  }
  .ant-slider-handle {
    border-color: #eb1f6d !important;
  }
  .ant-slider-dot-active {
    background-color: #eb1f6d;
    border-color: #eb1f6d !important;
  }
}
.preview-sq-collapse {
  .ant-collapse {
    border: 1px solid #ddd;
    box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    .ant-collapse-header {
      border-bottom: 1px solid #ddd !important;
      padding: 10px 0px !important;
      padding-left: 0px !important;
      .ant-collapse-header-text {
        padding-left: 15px;
      }
    }
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);