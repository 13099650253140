@import '~antd/dist/antd.less';
@import 'styles/animations';
@import 'styles/variables';

.app-32co {
  .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-tabs-nav-wrap {
    border-bottom: 1px solid #ddd;
  }

  .border-color {
    border-color: #ddd !important;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }

  .ant-picker,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-number,
  .ant-input-affix-wrapper {
    background-color: #f9f9fa !important;
    border: 1px solid #f9f9fa;
    color: #686c80 !important;
  }

  .ant-input-password input {
    background-color: transparent !important;
    border: none;
  }

  .ant-form-item-has-error .ant-input-number,
  .ant-form-item-has-error .ant-picker {
    border-color: #ff4d4f;
  }

  .ant-input {
    background-color: #f9f9fa;
    border: 1px solid #f9f9fa;
    color: #686c80 !important;
  }

  .remove-error-explain-height {
    .ant-form-item-explain {
      min-height: 0px;
    }
  }

  .collapse-btn {
    .anticon.anticon-down {
      margin-left: 0px;
      line-height: 0;
    }
  }

  // button {
  //     min-width: 150px;
  // }

  .status-container {
    background: #ddd;
    border-radius: 5px;
    padding: 5px;
    font-size: 10px;
  }

  .card-padding-15 {
    .ant-card-body {
      padding: 15px;
    }
  }
  .ant-spin {
    color: @darkBlueColor;
  }
  .ant-switch:not(.ant-switch-checked) {
    background-color: #d9d9d9;
  }
}

.ant-card,
.custom-accordion {
  border-radius: 5px !important;
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: transparent;
}

// do not remove or move above
.ant-select-dropdown,
.ant-select-dropdown-empty {
  z-index: 3000;
}

.ant-steps-item-finish {
  .ant-steps-item-icon {
    background-color: @darkBlueColor;
    border-color: @darkBlueColor;

    .ant-steps-icon {
      color: #fff;
    }
  }

  .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: @darkBlueColor;
  }
}

.ant-steps-item-wait {
  .ant-steps-item-icon {
    border-color: @primary-color;

    .ant-steps-icon {
      color: @primary-color;
    }
  }
}

// Ant override css
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  // background-color: var(--primary-color);
  background-color: #dddddd;
}

.pro-initial-case {
  .ant-steps-item-container {
    display: flex;
    flex-direction: column;
  }

  .ant-steps-item {
    .ant-steps-item-container {
      .ant-steps-item-tail {
        // padding: 65px 0 6px;
      }

      .ant-steps-item-icon {
        box-shadow: 1px 6px 7px #b5b4b4;
      }
    }

    .ant-steps-item-content {
      overflow: visible;

      .ant-steps-item-title {
        font-size: 10px;
        font-weight: 600;
        margin-left: -8px;
        color: @primary-color;
      }
    }
  }

  .ipr-title {
    .ant-steps-item-content {
      .ant-steps-item-title {
        font-size: 11px;
        margin-left: 10px;
        letter-spacing: 0.4px;
      }
    }
  }

  .additional-features {
    .ant-steps-item-content {
      .ant-steps-item-title {
        margin-left: -23px;
      }
    }
  }
}

.light-primary-bg {
  background-color: @draft-btn-bg-color;
}

.radio-btn {
  border: 1px solid;
  width: 20px;
  height: 20px;
  border-radius: 25px;
  margin-right: 10px;

  .radio-btn-dot {
    border: 1px solid #000;
    width: 10px;
    height: 10px;
    border-radius: 25px;
    background-color: #000;
    margin: auto;
    margin-top: 4px;
  }
}

.primary-background-color {
  background-color: @primary-background-color;
}

.secondary-background-color {
  background-color: @btn-selected-background-color;
}

.ant-tooltip-inner {
  a {
    color: white;
  }
}

.ant-upload.ant-upload-drag {
  background: @primary-background-color;
  padding: 10px 5px;
  height: auto;
}

.ant-layout {
  background: #f1f2f6;
}

.custom-shadow {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
}

.custom-shadow-2 {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

.upload-text {
  font-size: 12px;
}

.ant-tabs-tab .ant-tabs-tab-btn {
  color: #979797;
  font-size: 14px;
}

.ant-tabs-ink-bar {
  height: 2px;
  background: @primaryDarkBlueColor !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 500;
  color: @primaryDarkBlueColor;
}

h5.ant-typography {
  color: @primaryDarkBlueColor !important;
  font-size: 15px !important;
}

.ant-typography-disabled {
  color: @primaryDarkBlueColor !important;
  font-size: 14px !important;
  margin-bottom: 5px;
}

.lowercase {
  input {
    text-transform: lowercase;
  }
}

.hide-preview-title {
  .ant-image-mask-info:not(:first-child) {
    display: none;
  }
}

.text-head {
  font-family: @font-family;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: 20px;
  color: @primaryDarkBlueColor;
}

.text-body {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0.25rem;
  font-family: @font-family;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  line-height: 20px;
  color: #686c80;
}

.text-sub-head {
  font-family: @font-family;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  line-height: 20px;
  color: @primaryDarkBlueColor;
}

.format {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-icon-container {
  background: linear-gradient(180deg, #e8256b 0%, #ea4c2a 100%);
  border-radius: 50%;

  img {
    width: 35px;
  }
}

.reactour-your-simulation-info .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}

.chat-info-modal {
  width: 50px !important;
  width: 100vw !important;
  height: 100vh !important;

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    height: 100%;
  }
  img {
    position: absolute;
    right: -10px;
    bottom: 50px;
  }
}

.small-image-container {
  min-height: 140px;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .image-backdrop {
    background-color: #000;
    height: 100%;
    left: 0;
    opacity: 0.4;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .image-preview-btn {
    position: absolute;
    z-index: 3;
  }

  img {
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .overlay {
    opacity: 0;
  }

  &:hover {
    .overlay {
      opacity: 1;
    }
  }
}

.carousel-image-container {
  min-height: 320px;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    display: block;
    // position: absolute;
    // left: 50%;
    // transform: translate(-50%,0);
  }

  .overlay {
    opacity: 0;
  }

  &:hover {
    .overlay {
      opacity: 1;
    }
  }
}

.ant-btn-primary {
  background: @darkBlueColor !important;
  border-color: @darkBlueColor !important;
  border-radius: 20px !important;
  &:disabled {
    color: #999;
    opacity: 0.8;
  }
}

.btn-primary-redesigned {
  &:extend(.b-dark-blue);
  border: 0;
  border-radius: 5px;

  &:hover,
  &:focus {
    background-color: lighten(@darkBlueColor, 10%);
  }

  &:active {
    background-color: lighten(@darkBlueColor, 15%);
  }
  &:disabled,
  &:disabled:hover {
    color: rgba(225, 225, 225, 0.75) !important;
  }
}

.btn-secondary-redesigned {
  background-color: @btnSecondaryColor;
  color: @darkBlueColor;
  border: 1px solid #f0f0f0;
  border-radius: 5px;

  &:hover,
  &:focus {
    border: 1px solid #f0f0f0;

    color: @darkBlueColor;
    background-color: darken(@btnSecondaryColor, 5%);
  }

  &:active {
    border: 1px solid #f0f0f0;

    color: @darkBlueColor;
    background-color: darken(@btnSecondaryColor, 10%);
  }
}

.btn-default-redesigned {
  background-color: transparent;
  color: @darkBlueColor;
  border: 1px solid @darkBlueColor;

  &:hover,
  &:focus {
    color: @darkBlueColor;
    border: 1px solid @darkBlueColor;
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    color: @darkBlueColor;
    border: 1px solid @darkBlueColor;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.btn-danger-redesigned {
  background: @orangeColor !important;
  border-color: @orangeColor !important;
  color: white;
}

.radio-redesigned {
  .ant-radio-button-checked {
    background-color: @darkBlueColor !important;
  }
}

.step-redesigned {
  .step-item-content {
    display: none;
  }

  .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #dddddd;
  }
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  color: white;
  border-color: @darkBlueColor;
  background-color: @darkBlueColor;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon {
  border-color: @darkBlueColor;
  background-color: white;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
  color: @darkBlueColor;
}

.box-shadow-10 {
  box-shadow: 0px 10px 10px rgb(0 0 0 / 10%);
}

.app-primary-btn {
  background-color: #4133c5;
  border-color: #4133c5;
  border-radius: 20px;

  &:hover {
    color: #000 !important;
    border-color: #4133c5 !important;
  }
}

.ant-form-item-explain-error {
  margin-top: 5px;
}
.ant-popover-inner {
  border-radius: 5px;
}

.ant-modal-content {
  border-radius: 30px;
}

.modal-text-redesigned {
  color: @grey;
  font-size: 16.6px;
}

.ant-checkbox-wrapper-checked {
  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      color: black;
    }

    .ant-checkbox-inner::after {
      border-color: black;
    }
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: @darkBlueColor;
}

.ant-pagination-item-active,
.ant-pagination-item-active:hover {
  border-color: @darkBlueColor;

  a {
    color: @darkBlueColor;
  }
}

.ant-spin-dot-item {
  background-color: @darkBlueColor;
}

.img-crop-modal {
  .ant-modal-header {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }

  .ant-slider-track {
    background-color: @darkBlueColor;
  }
  .ant-slider-handle {
    border-color: @darkBlueColor;
  }

  .ant-btn-primary {
    &:extend(.btn-primary-redesigned);

    border-radius: 20px;
    width: 150px;
  }

  .ant-btn-default {
    &:extend(.btn-default-redesigned);

    border-radius: 20px;
    width: 150px;
  }
}

.ant-btn-default {
  // &:extend(.btn-default-redesigned);

  border-radius: 20px;
  // width: 150px;
}

.ant-popover-inner {
  border-radius: 20px;
}

.text-primary {
  color: @orangeColor !important;
  border-color: @orangeColor !important;
  border-radius: 20px !important;
  background: transparent;
}

.text-orange {
  color: @orangeColor !important;
  border-color: @orangeColor !important;
}

.search-bar {
  .ant-btn-primary {
    border-radius: 0px !important;
  }
}

.manufacturer-search-bar {
  .ant-input-search .ant-input:hover,
  .ant-input-search .ant-input:focus {
    border-color: transparent;
  }
}

// ant date picker
.ant-picker-panel-container {
  .ant-picker-cell-in-view {
    &.ant-picker-cell-selected .ant-picker-cell-inner {
      background: @darkBlueColor;
    }
    &.ant-picker-cell-today .ant-picker-cell-inner::before {
      border-color: @darkBlueColor;
    }
  }
  .ant-picker-today-btn {
    color: @darkBlueColor;
  }
}

.custom-radio-btn-group {
  .ant-radio-group {
    .ant-radio-button-wrapper {
      color: @primaryDarkBlueColor;
      border-color: #f0f0f0;
      background-color: #f5fbff;
      border-radius: 25px;
      border: none;
      margin: 5px 15px 5px 0px;
      font-size: 13px;
      letter-spacing: 0.2px;
      height: 38px;
    }

    .ant-radio-button-checked {
      background-color: @darkBlueColor;
      color: #feffff;
      border-radius: 25px;
      border: none;
    }

    *::before,
    *::after {
      background: none;
      background-color: transparent !important;
    }

    .ant-radio-button-wrapper {
      border-radius: 25px;
      border: 1px solid;
      color: @primaryDarkBlueColor;
      border-color: #f0f0f0;
      background-color: #f5fbff;
    }

    .ant-radio-button-wrapper-checked {
      background-color: @darkBlueColor;
      color: #feffff;
      border-radius: 25px;
      border: none;
    }

    input {
      width: 100px;
      background-color: #f1f1f1;
      color: @primaryDarkBlueColor;
      border-bottom-color: #9d9d9d;
      outline: 0;
      text-align: center;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: @primaryDarkBlueColor;
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: @primaryDarkBlueColor;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: @primaryDarkBlueColor;
    }
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #1c1b1d;
    border-color: #1c1b1d !important;
    color: #feffff;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  background-color: rgba(175, 175, 175, 0.11);
  &:vertical {
    width: 7px;
  }
  &:horizontal {
    height: 7px;
  }
}

&::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(183, 183, 183, 0.5);

  &:hover {
    background-color: rgba(183, 183, 183, 0.7);
  }
}

.custom-bounce {
  -webkit-animation: bounce 2s;
  animation: bounce 2s;
}

@-webkit-keyframes bounce {
  20%,
  53%,
  80%,
  0%,
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {
  20%,
  53%,
  80%,
  0%,
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.form-item-control {
  .ant-select-disabled .ant-select-selection-item {
    color: rgba(0, 0, 0, 0.85);
  }
}

// Antd preview image wrap/background/overlay
.ant-image-preview-wrap {
  background-color: #000000bd;
}

.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  background-color: #ddddddbf;
  &:hover {
    background-color: #dddddd78;
  }
  svg {
    color: #000;
  }
}

.ant-form-large .ant-form-item-label > label {
  min-height: 40px;
  height: auto;
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);