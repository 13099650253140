.signup-success {
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  .logo {
    font-size: 36px;
    color: white;
    margin-bottom: 50px;
  }
  .success-message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
      font-family: @font-family;
      font-weight: 600;
      font-size: 28px;
      display: block;
    }

    .content {
      font-family: @font-family;
      font-size: 16px;
      color: black;
      text-align: center;
    }
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);