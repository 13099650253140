@import '../../../../../styles/variables';

.sku-card-market-container {
  padding: 10px 10px 25px 10px;
  cursor: pointer;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;

  transition: 0.2s ease-in-out;

  border: 1.5px solid transparent;

  &:hover {
    border: 1.5px solid rgb(232, 226, 255);
    box-shadow: 11px 11px 11.6px 0 rgba(232, 226, 255, 0.4), -11px -11px 11.6px 0 rgba(232, 226, 255, 0.4);
  }

  .clear-aligner-bg {
    background-position: 90% 17px;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: unset;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
    min-height: 175px;
    margin-top: 14px;
    ul {
      list-style: none; /* Remove default bullets */

      li::before {
        content: '\2022';
        color: #8465fc;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
  .radio-btn {
    border: 1px solid;
    width: 20px;
    height: 20px;
    border-radius: 25px;
    margin-right: 10px;
    .radio-btn-dot {
      border: 1px solid #000;
      width: 10px;
      height: 10px;
      border-radius: 25px;
      background-color: #000;
      margin: auto;
      margin-top: 4px;
    }
  }
  .offer-badge {
    color: #1f16b0;
    background-color: #e3fff3;
    border: 1px dashed #1f16b0;
    padding: 5px 10px;
    border-radius: 35px;
    font-size: 12px;
    font-weight: 600;
    background-color: rgba(31, 22, 176, 0.1);
    width: fit-content;
  }
}

.sku-card-market__view-details {
  width: 130px;

  color: @darkBlueColor;
  background-color: #f5fbff;
  border: 1.5px solid @darkBlueColor;

  font-weight: 600;
}

.sku-card-market__btn-selected {
  width: 150px;

  background-color: @primaryDarkBlueColor;

  &:disabled {
    color: white;

    &:hover {
      color: white;
    }
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);