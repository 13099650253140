header.notion-header {
  display: none;
}

.help-choosing-materials-modal {
  .notion-page {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);