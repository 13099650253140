.assessment {
  position: relative;
}

.imgWrapper {
  border-radius: 10px;
  background-color: #e3e3e3;
  overflow: hidden;
}

.btnRemove {
  position: absolute;
  top: 0;
  right: 0;

  background-color: #fff;
  &:hover {
    background-color: #fff;
  }

  transform: translate(50%, -50%);
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);