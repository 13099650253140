@import '../../styles/variables';

.chip {
  padding: 7px;
  font-size: 12px;
  border-radius: 20px;
  background-color: @chipBgColor;
  color: @darkBlueColor;
  font-family: @font-family;
  border: 1px solid @chipBorderColor;
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);