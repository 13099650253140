@import 'styles/variables';

.smile-checkbox-group {
  .ant-checkbox-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;

    .ant-checkbox-wrapper {
      color: @primaryDarkBlueColor;
      border-radius: 8px;
      background-color: white;
      padding: 5px 20px;
      height: auto;
      position: relative;

      .ant-checkbox {
        position: absolute;
        left: 0px;
        top: calc(50% - 10px);
      }
    }
    .ant-checkbox-group-item {
      margin: 0;
      font-weight: 500;
    }
  }
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    &::after {
      border-color: #9c9c9c;
    }
  }
  .ant-checkbox-checked {
    &::after {
      border-color: #050d43;
    }
    .ant-checkbox-inner {
      background-color: transparent;
      border-color: #050d43;
      &::after {
        border: 2px solid #050d43;
        border-top: 0;
        border-left: 0;
        left: 25%;
      }
    }
  }
}

.smile-radio-group {
  .ant-radio {
    transform: scale(1.2);
  }
  .ant-radio-wrapper {
    margin: 0;
    margin-right: 15px;
    font-weight: 500;
  }
  .ant-radio-inner {
    border-color: #050d43;
  }
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: #050d43;
    }
    .ant-radio-inner::after {
      background-color: #050d43;
      transform: scale(0.7);
    }
  }
}

.sq-form-container {
  .nice-radio-group-wrapper {
    margin-top: 10px;
    .ant-radio-group {
      display: flex;
      justify-content: space-between;
      .ant-radio-button-wrapper {
        border-radius: 10px;
        margin: 0px;
        margin-right: 5px;
        border: none !important;
        box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.2);
        height: 60px;
        padding-top: 10px;
        .ant-radio-button-wrapper-checked {
          border-radius: 0px;
        }
        span {
          font-size: 28px;
        }
      }
      .ant-radio-button-checked {
        border: none !important;
      }
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border: 1px solid #050d43 !important;
      border-radius: 10px !important;
    }
  }
  .custom-textarea-styling {
    textarea {
      background-color: #fff;
      border: 1px solid #979797;
    }
  }
}
.sq-preview-form {
  .ant-radio-button-wrapper {
    pointer-events: none;
    height: 45px !important;
    padding-top: 5px !important;
  }
}

.custom-slider-styling {
  .ant-slider-rail {
    background-color: #d9d9d9;
  }
  .ant-slider-dot {
    top: -6px;
    width: 16px;
    height: 16px;
    border-color: #dfdfdfd9;
  }
  .ant-slider-mark-text {
    font-size: x-large;
  }
  .ant-slider-track {
    background-color: #eb1f6d !important;
  }
  .ant-slider-handle {
    border-color: #eb1f6d !important;
  }
  .ant-slider {
    .ant-slider-dot.ant-slider-dot-active {
      background-color: #eb1f6d;
      border-color: #eb1f6d !important;
    }
  }
  .ant-slider-disabled {
    .ant-slider-dot {
      border-color: #dfdfdfd9 !important;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: #e5f0ff;
    border-radius: 0px;
    border: 1px solid #050d43;
    color: #fff;
  }
  .nice-radio-group-wrapper.overflow-hidden {
    overflow: visible !important;
  }
}

.smile-questionnaire-dragger {
  .ant-upload.ant-upload-drag {
    background-color: #f9f9fa;
    .ant-upload-drag-container {
      div:first-child {
        flex-direction: column;
      }
    }
  }
  .mr-3.mt-1 {
    margin: 0;
  }
  .upload-text {
    justify-content: center;
    margin-top: 10px;
  }
}

@media screen and (max-width: 786px) {
  .smile-checkbox-group {
    .ant-checkbox-group {
      gap: 5px;
      flex-direction: column;
    }
  }
  .smile-radio-group {
    .ant-radio-wrapper {
      margin-bottom: 10px;
    }
  }
  .sq-form-container {
    .nice-radio-group-wrapper {
      .ant-radio-group {
        .ant-radio-button-wrapper {
          height: 43px;
          padding: 5px;
        }
      }
    }
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);