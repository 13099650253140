@import 'styles/variables';

.clearaligner-options {
  overflow: auto;

  .aligner-card {
    padding: 25px 10px;
    border: 1px solid #ddd;
    cursor: pointer;

    &:hover {
      box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
    }

    .clear-aligner-bg {
      background: url('../../../../assets/images/clear_aligner.png');
      background-color: #fff;
      background-position: 90% 17px;
      background-size: contain;
      background-repeat: no-repeat;
      background-attachment: unset;
      background-clip: border-box;
      -webkit-text-fill-color: inherit;
      min-height: 175px;
      margin-top: 14px;

      ul {
        list-style: none;
        /* Remove default bullets */

        li::before {
          content: '\2022';
          color: #8465fc;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }

    .radio-btn {
      border: 1px solid;
      width: 20px;
      height: 20px;
      border-radius: 25px;
      margin-right: 10px;

      .radio-btn-dot {
        border: 1px solid #000;
        width: 10px;
        height: 10px;
        border-radius: 25px;
        background-color: #000;
        margin: auto;
        margin-top: 4px;
      }
    }
  }

  .aligner-card-no-shadow {
    cursor: default;

    &:hover {
      box-shadow: none;
    }
  }
}

.clear-aligner-modal-bg {
  background: url('../../../../assets/images/clear_aligner.png');
  background-color: #fff;
  background-position: -5% -10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: unset;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
  min-height: 220px;
}

.specifications-list {
  background-color: @primary-background-color;

  .sticky {
    background-color: @primary-background-color;
  }

  .specifications-description {
    min-height: 120px;

    ul {
      list-style: none;

      /* Remove default bullets */
      li::before {
        content: '\2022';
        // color: @btn-primary-color;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
}

.ant-modal-footer {
  text-align: center;
}

.offer-badge {
  color: #039f61;
  background-color: #e3fff3;
  border: 1px dashed #039f61;
  padding: 3px 7px;
  border-radius: 15px;
  font-size: 9px;
}

.radio-green-dot {
  // input[type="radio"]:checked+label{ font-weight: bold; }
}

.fee-details-container {
  .ant-card-body {
    padding: 14px;
  }
}

.chat-closed {
  bottom: 10px;
  right: 15px;

  .chat-icon-container {
    background: linear-gradient(180deg, #e8256b 0%, #ea4c2a 100%);
    border-radius: 50%;

    img {
      width: 35px;
    }
  }
}

.checkin-status-active {
  background-color: #f8fdff;
  border-left: 3px solid #59c3f3;
}

.checkin-status-completed {
  background-color: #039f6112;
  border-left: 3px solid #039f61;
}

.checkin-status-canceled {
  background-color: #fffaf7;
  border-left: 3px solid #dc5536;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  color: currentColor;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
  left: 10px;
  z-index: 2;
  color: white;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
  right: 10px;
  z-index: 2;
  color: white;
}

.inputfield-gray-bg {
  width: 80%;
  background-color: #f3f3f3;
  padding: 8px 5px;
}

.non-inputfield-bg {
  width: 80%;
  background-color: #f3f3f3;
  padding: 20px 10px;
}

.ant-typography-edit-content {
  margin-left: 12px;
}

.text-red {
  color: red;
}

.treatment-close-btn {
  background-color: @primaryDarkBlueColor;
  color: #fff;
  border-radius: 20px;
}

.uploaded-image-container {
  .small-image-container {
    // width: 100px;
    height: 100px;
    border-radius: 4px;
    overflow: hidden;

    .ant-image {
      height: 100%;
      display: flex;
      justify-content: center;
      overflow: hidden;
      border-radius: 4px;

      img {
        width: auto;
        height: 100%;
      }
    }
  }
}

.treatment-design-tour {
  padding: 20px;
}

.reactour-hide-back-btn {
  button[data-tour-elem='left-arrow'] {
    display: none;
  }

  div[data-tour-elem='controls'] {
    justify-content: end;
  }
}

.recommended-filters {
  .ant-checkbox-wrapper {
    color: #09263d;
    border: 1px solid #d9d9d9;
    border-radius: 25px;
    background-color: white;
    font-size: 13px;
    letter-spacing: 0.2px;
    padding: 5px 0px;
    min-height: 34px;
    margin-bottom: 7px;
    margin-left: 10px;
    text-align: center;

    svg {
      display: none;
      vertical-align: middle;
    }
  }

  .ant-checkbox-wrapper-checked {
    background-color: #e5f0ff;
    border: 1px solid #9dbff9;
    color: #050d43;
    box-shadow: 0 0 0 2px rgba(0, 96, 255, 0.12);

    svg {
      margin-left: 5px;
      display: inline-block;
    }
  }

  .ant-checkbox {
    display: none;
  }
}

#wizard-clear-aligners-info {
  .ant-collapse-content-box {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.nice-collapse-gradient {
  .nice-collapse-wrapper,
  .ant-collapse-header {
    background: linear-gradient(180deg, #e8256b 0%, #ea4c2a 100%);
    transition: 0.5s ease-in-out;

    .ant-collapse-header-text {
      color: white;
    }
  }

  .nice-collapse-wrapper {
    padding: 15px 5px;
    .ant-collapse-header {
      background: transparent !important;
      background-color: transparent !important;
      color: white !important;
    }
  }
  .ant-collapse,
  .ant-collapse-content,
  .ant-collapse-content-box,
  .ant-collapse-header-text {
    background: transparent;
  }
}

.nice-collapse-transparent {
  .nice-collapse-wrapper {
    background: linear-gradient(180deg, #e8256b 0%, #ea4c2a 100%);
    transition: 0.5s ease-in-out;
    padding: 15px 5px;

    .ant-collapse-header {
      background: unset !important;
      color: white !important;
      background-color: unset !important;
    }
  }

  .ant-collapse,
  .ant-collapse-content,
  .ant-collapse-content-box {
    background: unset;
  }

  .ant-collapse-content-box {
    padding: 0px;
  }
}

.border-linear-gradient {
  background: @appGradient;
  padding: 2px;
  border-radius: 3px;
}

.patient-proposal-custom-tooltip {
  .ant-tooltip-inner {
    padding: 0;
  }
}

.custom-image-previewer {
  .ant-image-preview-switch-left,
  .ant-image-preview-switch-right {
    display: none;
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);