.specialistAdviceContainer {
  white-space: pre-wrap;

  .head {
    color: #050d43;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .title {
    color: black;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  .content {
    color: #808080;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;

    .modify-chip {
      color: #0063f7;
    }
  }

  .asterisk {
    color: red;
  }
}

.callOutsComponent {
  width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 5px 30px 20px 30px;

  .titleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    font-size: 16px;

    .circle {
      width: 20px;
      height: 42px;
      border: 5px solid;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      margin-right: 15px;
    }
  }

  .contentWrapper {
    margin-top: 5px;
    padding: 20px;
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    color: #808080;
  }

  &.green {
    background: #dcfad4;

    .titleWrapper {
      color: #039f61;

      .circle {
        border-color: #039f61;
      }
    }
  }

  &.yellow {
    background: #fef7cb;

    .titleWrapper {
      color: #e5b800;

      .circle {
        border-color: #e5b800;
      }
    }
  }

  &.red {
    background: #f8e7e7;

    .titleWrapper {
      color: #dc5536;

      .circle {
        border-color: #dc5536;
      }
    }
  }
}

@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00EA8C;@switch-color: #050d43;@link-color: #4133C5;@disabled-color: rgba(0, 0, 0, 0.85);